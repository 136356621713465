import { themeColor } from '@common/constant/theme';
import styled from 'styled-components';
import search_img from '@assets/icons/common/input_search.svg';
import delete_img from '@assets/icons/common/input_delete.svg';
import { useEffect, useState } from 'react';
import SearchComponent from './SearchComponent';
import { PatientApi } from 'src/service/patientApi';
import { positionStyle, sizeStyle } from '@common/constant/commonStyle';
import { useNavigate } from 'react-router-dom';
import { patientIdAtom } from '@common/atom/patient';
import { useSetAtom } from 'jotai';
import { ModalStateAtom } from '@common/atom/modal';
import { useQuery } from '@tanstack/react-query';
import { PatientResponse } from '@common/type/patient';
import { Response } from '@common/type/response';
import useDebounce from '@hooks/useDebounce';
import useFocus from '@hooks/useFocus';

type Props = {
  page: 'main' | 'header';
};

const SearchBox = ({ page }: Props) => {
  const [similarPatients, setSimilarPatients] = useState<PatientResponse[]>([]);
  const [patientId, setPatientId] = useState('');
  const setPatientNum = useSetAtom(patientIdAtom);
  const setModalState = useSetAtom(ModalStateAtom);
  const navigate = useNavigate();

  const [patientArray, setPatientArray] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { focusOn, inputRef, inputFocus, inputBlur } = useFocus();
  const debouncedValue = useDebounce(patientId, 300);

  const addFn = () => {
    setPatientId('');
    setModalState('addPatient');
    setPatientNum(patientId);
  };
  const moveFn = (url: string) => {
    setPatientId('');
    navigate(url);
    inputRef.current?.blur();
  };

  const resetHandler = () => {
    setPatientId('');
  };

  const keyUpHandler = (key: string) => {
    if (key === 'Escape') resetHandler();
    else if (key === 'Enter') {
      if (selectedIndex === -1) {
        if (
          similarPatients.length > 0 &&
          similarPatients[0].patient_id === patientId
        )
          moveFn(`/patient/${similarPatients[0].patient_uuid}`);
        else addFn();
      } else moveFn(`patient/${patientArray[selectedIndex]}`);
    } else if (key === 'ArrowUp') {
      if (selectedIndex > -1) setSelectedIndex(selectedIndex - 1);
    } else if (key === 'ArrowDown') {
      if (patientArray.length - 1 > selectedIndex)
        setSelectedIndex(selectedIndex + 1);
    }
  };

  const inputHandler = (value: string) => {
    value && setPatientId(value);
    const validPattern = /^[0-9A-Za-z]*$/;
    if (!validPattern.test(value)) {
      setPatientId(value.slice(0, -1));
    } else {
      setPatientId(value);
    }
  };

  useEffect(() => {
    const similarData = async () => {
      try {
        const response = await PatientApi.getPatientsListByPatientId(
          patientId,
          5,
        );
        setSimilarPatients(response.data);

        const data: string[] = [];

        response.data.map((num: PatientResponse) => {
          data.push(num.patient_uuid);
        });
        recentPatientData?.map(num => {
          data.push(num.patient_uuid);
        });
        setPatientArray(data);
      } catch (err) {
        // 딱히 처리할 사항들이 없음
      }
    };

    if (debouncedValue) similarData();
  }, [debouncedValue]);

  const recentPatient = useQuery<Response<PatientResponse[]>>({
    queryKey: ['getRecentPatient', 3],
    queryFn: () => PatientApi.getPatientsRecentReads(3),
  });
  const recentPatientData = recentPatient.data?.data;

  if (recentPatient.isError) throw recentPatient.error;

  return (
    <Component>
      <Content>
        <SearchIcon
          src={search_img}
          width="18"
          height="18"
          alt="search box icon"
        />
        <Input
          data-testid="searchbox-input"
          $page={page}
          $focusOn={focusOn}
          $value={patientId}
          maxLength={20}
          type="text"
          value={patientId}
          onKeyUp={e => keyUpHandler(e.key)}
          onChange={e => {
            inputHandler(e.target.value);
          }}
          onFocus={inputFocus}
          onBlur={inputBlur}
          ref={inputRef}
          placeholder="Search or add data by patient ID"
        />
        {patientId !== '' ? (
          <DeleteIcon type="reset" onClick={resetHandler}>
            <img
              src={delete_img}
              width="15"
              height="15"
              alt="delete search box value"
            />
          </DeleteIcon>
        ) : null}
      </Content>
      {focusOn && patientId ? (
        <SearchComponent
          page={page}
          patientData={similarPatients}
          recentData={recentPatientData}
          patientId={patientId}
          selectedIndex={selectedIndex}
          addFn={addFn}
          moveFn={moveFn}
        />
      ) : null}
    </Component>
  );
};

export default SearchBox;

const Component = styled.div``;

const Content = styled.div`
  ${sizeStyle('660px')()};
  display: flex;
  position: relative;
`;

const inputRadius = (page: string, focusOn: boolean, value: string) => {
  const first = page === 'main';
  const second = value && focusOn;

  if ((first && value === '') || (first && !second)) return '9999px';
  else if (first && second) return '25px 25px 0px 0px';
  else if (!first && second) return '10px 10px 0px 0px';
  else return '10px';
};

const Input = styled.input<{
  $page: string;
  $focusOn: boolean;
  $value: string;
}>`
  ${sizeStyle('660px')('42px')}
  border-radius: ${props =>
    inputRadius(props.$page, props.$focusOn, props.$value)};
  border: ${props =>
    props.$page === 'main' ? `1px solid ${themeColor.green_200}` : 'none'};
  border-bottom: ${props => props.$value !== '' && props.$focusOn && 'none'};
  padding: 0px 50px 0px 50px;
  font-size: 18px;
  background: ${props =>
    props.$page === 'main' ? 'white' : 'rgba(70, 89, 101, 0.1)'};

  &::placeholder {
    text-align: center;
    color: ${themeColor.green_400};
  }
`;
const SearchIcon = styled.img`
  ${sizeStyle('18px')()}
  ${positionStyle('absolute')('14px')()('20px')()}
  margin: 0;
`;
const DeleteIcon = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  ${positionStyle('absolute')('14px')()()('24px')}
`;
