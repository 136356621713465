import { Response } from '@common/type/response';

class Exception extends Error {
  data: Response<{ field: string; message: string }[]> | undefined;

  constructor(
    data: Response<{ field: string; message: string }[]> | undefined,
  ) {
    super();
    this.data = data;
  }
}
export default Exception;
