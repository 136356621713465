import { useAtom, useAtomValue } from 'jotai';
import Portal from '../../common/Portal';
import styled from 'styled-components';
import { subModalDataAtom, subModalEnabledAtom } from '@common/atom/subModal';
import { themeColor } from '@common/constant/theme';
import {
  flexStyle,
  positionStyle,
  sizeStyle,
} from '@common/constant/commonStyle';

/**
 * 서브 모달은 사용법이 일반 모달과 약간 달라져서 아쉽네요
 * 이렇게 만든 이유는 서브 모달은 일반 모달보다 복잡도 낮고 패턴화하기 쉬워서
 * 변수를 받는 것으로 굳이 수많은 서브모달 컴포넌트를 만들지 않아도 해결이 되기 때문에
 * 이런 식으로 짰습니다
 */

const SubModal = () => {
  const [enabled, setEnabled] = useAtom(subModalEnabledAtom);
  const data = useAtomValue(subModalDataAtom);
  const isOnlyOK = !data.no ? true : false;

  return (
    <Portal>
      {enabled && (
        <ModalArea>
          <Frame
            onClick={() => {
              setEnabled(false);
            }}
          />
          <Container $type={data.type}>
            {data.icon}
            <Wrapper>
              <Section>
                <Title className="typo-title-md">{data.title}</Title>
                <Text className="typo-body-sm">{data.description}</Text>
              </Section>
              <ButtonSection>
                {!isOnlyOK && (
                  <CustomButton
                    className="typo-label-md"
                    $type={data.type}
                    $isOk={false}
                    onClick={data.no}>
                    No
                  </CustomButton>
                )}
                <CustomButton
                  className="typo-label-md"
                  $type={data.type}
                  $isOk={true}
                  onClick={data.yes}>
                  {!isOnlyOK ? 'Yes' : 'OK'}
                </CustomButton>
              </ButtonSection>
            </Wrapper>
          </Container>
        </ModalArea>
      )}
    </Portal>
  );
};
const ModalArea = styled.div`
  ${flexStyle()()('center')()}
  z-index: 10;
  position: relative;
`;
const Frame = styled.div`
  ${positionStyle('fixed')('0px')()()()}
  ${sizeStyle('100vw')('100vh')}
  background-color: rgba(255, 255, 255, 0);
`;
const color = {
  error: 'red',
  warning: 'orange',
  success: 'green',
};
const Container = styled.div<{ $type: 'error' | 'warning' | 'success' }>`
  ${flexStyle('column')('20px')('center')('center')}

  background-color: ${props => themeColor[`${color[props.$type]}_modal`]};
  border: 1px solid ${props => themeColor[color[props.$type]]};
  box-shadow: ${props => themeColor[`${color[props.$type]}_shadow`]};
  border-radius: 6px;
  box-sizing: border-box;

  ${positionStyle('fixed')('50%')()('50%')()}
  transform: translate(-50%, -50%);

  ${sizeStyle('360px')('264px')}
`;
const Wrapper = styled.div`
  ${flexStyle('column')('20px')()()}
`;
const Section = styled.div`
  ${flexStyle('column')('4px')()('center')}
`;
const ButtonSection = styled.div`
  ${flexStyle()('20px')('center')()}
`;
const CustomButton = styled.button<{
  $type: 'error' | 'warning' | 'success';
  $isOk: boolean;
}>`
  ${sizeStyle('110px')('26px')}
  border: 1px solid
    ${props =>
    props.$isOk ? themeColor[color[props.$type]] : themeColor.green_600};
  border-radius: 30px;
  color: ${props => (props.$isOk ? 'white' : themeColor.green_600)};
  background-color: ${props =>
    props.$isOk ? themeColor[color[props.$type]] : 'white'};
  font-weight: 500;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;
const Title = styled.div``;
const Text = styled.div`
  text-align: center;
  color: ${themeColor.green_600};
  ${sizeStyle()('100%')}
`;

export default SubModal;
