import { Response } from '@common/type/response';
import Exception from './exception';

/** 40001, post는 선행된 pre가 있어야 할 것. */
class EpiStatusException extends Exception {
  constructor(
    data: Response<{ field: string; message: string }[]> | undefined,
  ) {
    super(data);
  }
}
export default EpiStatusException;
