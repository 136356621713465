import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'src/service/authApi';
import arrowUp from '@assets/icons/arrow/arrow_up.svg';
import arrowDown from '@assets/icons/arrow/arrow_down.svg';
import styled from 'styled-components';
import { flexStyle, fontStyle } from '@common/constant/commonStyle';
import { themeColor } from '@common/constant/theme';
import useToast from '@hooks/useToast';
import { Response } from '@common/type/response';
import { GetUser } from '@common/type/auth';
import { UserApi } from 'src/service/userApi';

const HeaderDrop = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pushToast } = useToast();
  const { data, error } = useQuery<Response<GetUser>>({
    queryKey: ['getUsers'],
    queryFn: () => UserApi.getUsers(),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: localStorage.getItem('Session-Id') !== null,
  });
  const arrowImg = isOpen ? arrowUp : arrowDown;

  const accountSettingHanlder = () => {
    setIsOpen(false);
    navigate('/account');
  };

  const signoutHandler = () => {
    setIsOpen(false);
    AuthApi.postSignout();
    queryClient.removeQueries();
    localStorage.clear();
    pushToast('Your have been successfully logged out.', 'success');
    navigate('/signin');
  };

  if (!data || error || !localStorage.getItem('Session-Id')) return <div></div>;

  return (
    <Container>
      <Select
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <Menu className="typo-body-md">MENU</Menu>
        <img src={arrowImg} alt="menu" width="24" height="24" />
      </Select>
      {isOpen && (
        <List>
          <Section>
            <Title>setting</Title>
            <Item onClick={accountSettingHanlder}>Account setting</Item>
            <Item>Treatment setting</Item>
          </Section>
          <Section>
            <Title>About</Title>
            <Item>Privacy</Item>
            <Item>Security</Item>
            <Item>Terms of service</Item>
            <Item>Contact us</Item>
          </Section>
          <Section>
            <Item onClick={signoutHandler}>Sign out</Item>
          </Section>
        </List>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 143px;
`;
const Select = styled.div`
  ${flexStyle()()('end')('center')};
  cursor: pointer;
`;
const Menu = styled.div`
  ${flexStyle()('2px')('center')('center')};
  color: ${themeColor.green_600};
`;

const List = styled.div`
  position: absolute;
  z-index: 2;
  ${flexStyle('column')()()('flex-start')};
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${themeColor.green_50};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 6px;

  > div:not(:last-child) {
    border-bottom: 1px solid ${themeColor.green_100};
  }
`;
const Section = styled.div`
  ${flexStyle('column')()()('flex-start')};
  box-sizing: border-box;
  padding: 4px 0px;
  cursor: default;
`;
const Title = styled.div`
  ${fontStyle('10px')(400)(16)};
  letter-spacing: -0.25px;
  ${flexStyle()()()('center')};
  color: ${themeColor.green_400};

  padding: 0px 12px;
`;
const Item = styled.div`
  padding: 0px 26px;
  ${fontStyle('12px')(400)(24)};
  letter-spacing: -0.25px;
  color: ${themeColor.green_600};
  width: 143px;
  box-sizing: border-box;

  &:hover {
    background: ${themeColor.blue_bg};
  }
`;

export default HeaderDrop;
