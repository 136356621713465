import axios from 'axios';

const devMode = process.env.NODE_ENV;
const vite = import.meta.env.VITE_BASE_URL;

const baseUrl = devMode === 'mock' ? '' : vite;

export const instance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use(
  config => {
    if (
      config.url?.includes('patients') ||
      config.url?.includes('exams') ||
      config.url?.includes('treatments') ||
      config.url?.includes('charts') ||
      config.url?.includes('crts') ||
      config.url?.includes('charts') ||
      config.url?.includes('my-info') ||
      config.url?.includes('signout')
    ) {
      config.headers['Session-Id'] = localStorage.getItem('Session-Id');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // 40100 인증 -> 세션이 만료되었으니 맨 처음 로그인 페이지로 이동
    // 40300 인가 -> 권한이 없으니 메인 페이지로 이동
    const pathName = location.pathname;
    if (
      (error.response.status === 401 &&
        pathName !== '/signin' &&
        !pathName.includes('signup') &&
        !pathName.includes('password-reset')) ||
      (error.response.status === 404 &&
        error.config.url === '/users/my-info' &&
        pathName !== '/signin')
    ) {
      location.replace('/signin');
    } else if (error.response.status === 403 && pathName !== '/') {
      location.replace('/');
    }
    return Promise.reject(error);
  },
);
