import styled from 'styled-components';
import loocus_logo from '@assets/images/loocus_myopia_logo.png';
import { useNavigate } from 'react-router-dom';
import { flexStyle, sizeStyle } from '@common/constant/commonStyle';
import SearchBox from '@ui/feature/searchBox/SearchBox';
import HeaderDrop from './HeaderDrop';

type Props = {
  searchBox: boolean;
};

const Header = ({ searchBox }: Props) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <LeftSection>
          <img
            src={loocus_logo}
            width="185"
            height="20"
            alt="logo"
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          />
        </LeftSection>
        {searchBox && <SearchBox page="header" />}
        <RightSection>
          <HeaderDrop />
        </RightSection>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: rgba(245, 248, 253, 0.86);
  border-bottom: 1px solid #dfe4e7;
  ${sizeStyle('100%')('78px')}
  min-width : 1400px;
  ${flexStyle()()('center')('center')};
`;
const Content = styled.div`
  ${flexStyle()()('space-between')('center')};
  ${sizeStyle('1400px')()};
`;
const LeftSection = styled.div``;
const RightSection = styled.div``;

export default Header;
