import { Response } from '@common/type/response';
import Exception from './exception';

class ForbiddenException extends Exception {
  constructor(
    data: Response<{ field: string; message: string }[]> | undefined,
  ) {
    super(data);
  }
}
export default ForbiddenException;
