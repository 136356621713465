import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
};

/** 모달, 토스트를 위한 Portal입니다. DOM 바깥에 배치할 컴포넌트가 추가로 발생하면 사용해주세요~ */
const Portal: React.FC<Props> = ({ children }: Props) => {
  return <>{createPortal(children, document.body as Element)}</>;
};

export default Portal;
