import { atom } from 'jotai';

/** 서브 모달의 상태. 모달과 다르게 서브 모달안에 들어갈 내용을 다 넣어야 함 */
export const subModalAtom = atom<SubModalType>({
  enabled: false,
  data: {
    type: 'error',
    icon: <></>,
    title: '',
    description: <></>,
    yes: () => {},
    no: () => {},
  },
});

export type SubModalType = {
  enabled: boolean;
  data: SubModalData;
};
export type SubModalData = {
  type: 'error' | 'warning' | 'success';
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
  yes: () => void;
  no?: () => void;
};

export const subModalEnabledAtom = atom(
  get => get(subModalAtom).enabled,
  (_get, set, newEnabled: boolean) =>
    set(subModalAtom, prev => ({ ...prev, enabled: newEnabled })),
);
/** OK만 있는 모달로 쓰고 싶으면 no 빼고 세팅하세요 */
export const subModalDataAtom = atom(
  get => get(subModalAtom).data,
  (_get, set, newData: SubModalData) =>
    set(subModalAtom, prev => ({ ...prev, data: newData })),
);
