import styled from 'styled-components';
import Header from '@ui/common/Header';
import Footer from '@ui/common/Footer';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Toast from '@ui/common/Toast.tsx';
import SubModal from '@ui/feature/subModal/SubModal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorComponent from '@ui/common/error/ErrorComponent';
import { flexStyle, fontStyle } from '@common/constant/commonStyle';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { themeColor } from '@common/constant/theme';
import PatientInfo from '@ui/feature/patient/PatientInfo';
import TabSwitcher from '@ui/feature/tab/TabSwitcher';
import errorBackground from '@assets/images/background.svg';

const Modal = lazy(() => import('@ui/feature/modal/Modal'));
const Home = lazy(() => import('@ui/pages/Home'));
const Landing = lazy(() => import('@ui/pages/Landing'));
const Communication = lazy(() => import('@ui/pages/Communication'));
const Patient = lazy(() => import('@ui/pages/Patient'));
const Exam = lazy(() => import('@ui/pages/Exam'));
const Signup = lazy(() => import('@ui/pages/Signup'));
// const Crt = lazy(() => import('@ui/pages/Crt'));
const Signin = lazy(() => import('@ui/pages/Signin'));
const Account = lazy(() => import('@ui/pages/Account'));
const SelectSignup = lazy(() => import('@ui/pages/SelectSignup'));
const ForgotPassword = lazy(() => import('@ui/pages/ForgotPassword'));

// [refactor] 전체적인 레이아웃과 구조가 복잡해졌는데 더 단순하게 만들 여지 없나요?
const Layout = ({ children }: { children: JSX.Element }) => (
  <>
    <Modal />
    <SubModal />
    <Toast />
    {children}
  </>
);

const InnerLayout = ({
  color,
  children,
}: {
  color: 'white' | 'pale';
  children: JSX.Element;
}) => (
  <Container $color={color}>
    <Header searchBox={false} />
    <Content>{children}</Content>
    <Footer />
  </Container>
);

const ErrorPage = ({ children }: { children: JSX.Element }) => {
  // todo : 빌드 시 styled component 적용 안되는 문제 확인
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${errorBackground})`,
      }}>
      {children}
    </div>
  );
};

const PatientLayout = ({ children }: { children: JSX.Element }) => {
  const { patient_uuid } = useParams();
  // [refactor] url의 역할이 단일적이지 않고 location.pathname을 state로 만들어서 쓰는 부분도 마음에 안듬..
  const location = useLocation();
  const url: Record<string, string> = useMemo(() => {
    return {
      data: 'exam',
      chart: 'patient',
      // calculator: 'crt',
      exam: 'data',
      patient: 'chart',
      // crt: 'calculator',
    };
  }, []);

  const [tab, setTab] = useState<string>(url[location.pathname.split('/')[1]]);
  const navigate = useNavigate();

  const handler = (tab: string) => {
    setTab(tab);
    navigate(`/${url[tab]}/${patient_uuid}`);
  };

  useEffect(() => {
    setTab(url[location.pathname.split('/')[1]]);
  }, [url, location.pathname]);

  return (
    <>
      <Container $color="white">
        <Header searchBox={true} />
        <PatientInfo />
        <Switched>
          <TabSwitcher
            tab={tab}
            // tabList={['chart', 'data', 'calculator']}
            tabList={['chart', 'data']}
            setTab={handler}
          />
          <SubText>
            MyOPIA Chart is automatically updated whenever you add or edit data.
          </SubText>
        </Switched>
        <ContentColored>{children}</ContentColored>
        <Footer />
      </Container>
    </>
  );
};

const CommunicationLayout = ({ children }: { children: JSX.Element }) => (
  <Container $color="white">
    <Content>{children}</Content>
  </Container>
);

// [배포]
const signupPage =
  import.meta.env.MODE === 'production'
    ? []
    : [
        {
          path: '/signup',
          element: (
            <Layout>
              <InnerLayout color="pale">
                <Suspense fallback={<></>}>
                  <SelectSignup />
                </Suspense>
              </InnerLayout>
            </Layout>
          ),
        },
      ];
// [배포]
const selectSignupPage =
  import.meta.env.MODE === 'production'
    ? []
    : [
        {
          path: '/signup/:user_type',
          element: (
            <Layout>
              <InnerLayout color="pale">
                <Suspense fallback={<></>}>
                  <Signup />
                </Suspense>
              </InnerLayout>
            </Layout>
          ),
        },
      ];

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: (
      <Layout>
        <InnerLayout color="white">
          <ErrorComponent type="404" />
        </InnerLayout>
      </Layout>
    ),
    element: (
      <Layout>
        <InnerLayout color="white">
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        </InnerLayout>
      </Layout>
    ),
  },
  {
    path: '/landing',
    element: (
      <Layout>
        <Suspense fallback={<></>}>
          <Landing />
        </Suspense>
      </Layout>
    ),
  },
  {
    path: '/communication/:patient_uuid',
    element: (
      <Layout>
        <CommunicationLayout>
          <Suspense fallback={<></>}>
            <Communication />
          </Suspense>
        </CommunicationLayout>
      </Layout>
    ),
    errorElement: (
      <ErrorPage>
        <ErrorComponent type="ERROR" />
      </ErrorPage>
    ),
  },
  {
    path: '/signin',
    element: (
      <Layout>
        <InnerLayout color="pale">
          <Suspense fallback={<></>}>
            <Signin />
          </Suspense>
        </InnerLayout>
      </Layout>
    ),
  },
  ...signupPage,
  ...selectSignupPage,
  {
    path: '/patient/:patient_uuid',
    element: (
      <Layout>
        <PatientLayout>
          <Suspense fallback={<></>}>
            <Patient />
          </Suspense>
        </PatientLayout>
      </Layout>
    ),
    errorElement: (
      <ErrorPage>
        <ErrorComponent type="ERROR" />
      </ErrorPage>
    ),
  },
  {
    path: '/password-reset',
    element: (
      <Layout>
        <InnerLayout color="pale">
          <Suspense fallback={<></>}>
            <ForgotPassword />
          </Suspense>
        </InnerLayout>
      </Layout>
    ),
    errorElement: (
      <ErrorPage>
        <ErrorComponent type="ERROR" />
      </ErrorPage>
    ),
  },
  {
    path: '/exam/:patient_uuid',
    element: (
      <Layout>
        <PatientLayout>
          <Suspense fallback={<></>}>
            <Exam />
          </Suspense>
        </PatientLayout>
      </Layout>
    ),
    errorElement: (
      <ErrorPage>
        <ErrorComponent type="ERROR" />
      </ErrorPage>
    ),
  },
  {
    path: '/account',
    element: (
      <Layout>
        <InnerLayout color="pale">
          <Suspense fallback={<></>}>
            <Account />
          </Suspense>
        </InnerLayout>
      </Layout>
    ),
  },
  // {
  //   path: '/crt/:patient_uuid',
  //   element: (
  //     <PatientLayout>
  //       <Suspense fallback={<></>}>
  //         <Crt />
  //       </Suspense>
  //     </PatientLayout>
  //   ),
  //   errorElement: <ErrorComponent type="ERROR" />,
  // },
  {
    path: '/404',
    element: (
      <Layout>
        <InnerLayout color="white">
          <ErrorComponent type="404" />
        </InnerLayout>
      </Layout>
    ),
  },
]);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

const Container = styled.div<{ $color: 'white' | 'pale' }>`
  ${flexStyle('column')()()()}
  min-height: 100vh;
  position: relative;
  background-color: ${props =>
    props.$color === 'white' ? 'white' : themeColor.blue_bg};
`;
const Content = styled.div`
  flex: 1;
`;
const ContentColored = styled.div`
  flex: 1;
  background: ${themeColor.blue_bg};
  min-width: 1400px;
  min-height: 100vh;
`;
const SubText = styled.div`
  ${fontStyle('14px')(300)(24)}
  letter-spacing: -0.25px;
  text-align: center;
  margin: 0 auto;
  width: 1400px;
  color: ${themeColor.green_400};
`;

const Switched = styled.div`
  background: ${themeColor.blue_bg};
  min-width: 1400px;
  border-top: 1px solid ${themeColor.green_100};
`;

export default App;
