import styled from 'styled-components';
import PatientBox from './PatientBox';
import { useParams } from 'react-router-dom';
import { sizeStyle } from '@common/constant/commonStyle';
import { NoUuidError } from 'src/exception';
import { PatientResponse } from '@common/type/patient';
import { DateBarToDot } from '@common/utils/date';
import { useQuery } from '@tanstack/react-query';
import { Response } from '@common/type/response';
import { GetUser } from '@common/type/auth';

type Props = {
  data: PatientResponse;
};

const PatientInfoData = ({ data }: Props) => {
  const { patient_uuid } = useParams();
  if (!patient_uuid) throw new NoUuidError();

  const users = useQuery<Response<GetUser>>({
    queryKey: ['getUsers'],
  });

  const doctorNameFormatter = (
    firstName: string | undefined,
    lastName: string | undefined,
  ) => {
    if (firstName && lastName) return `${firstName} ${lastName}`;
    else return undefined;
  };

  const sexFormatter = (value: 'FEMALE' | 'MALE') => {
    return value === 'FEMALE' ? 'Female' : 'Male';
  };

  const extractedData = {
    patient: [
      { title: 'Patient ID', value: data.patient_id },
      { title: 'Name', value: `${data.first_name} ${data.last_name}` },
      { title: 'Birth date', value: DateBarToDot(data.birth_date) },
      { title: 'Sex', value: sexFormatter(data.sex) },
    ],
    doctor: [
      {
        title: 'Name',
        value: doctorNameFormatter(
          data.doctor?.first_name,
          data.doctor?.last_name,
        ),
      },
    ],
  };

  return (
    <Container>
      <PatientBox data={extractedData.patient} dataType="patient">
        Patient Info.
      </PatientBox>
      {users.data?.data?.account_type === 'INSTITUTION' && (
        <PatientBox data={extractedData.doctor} dataType="doctor">
          Doctor Info.
        </PatientBox>
      )}
    </Container>
  );
};

export default PatientInfoData;

const Container = styled.div`
  ${sizeStyle('80%')('100%')}
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;
