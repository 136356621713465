import { Response } from '@common/type/response';
import Exception from './exception';

/** 40002, 나이는 만 5 ~ 19세 일 것 */
class AgeException extends Exception {
  constructor(
    data: Response<{ field: string; message: string }[]> | undefined,
  ) {
    super(data);
  }
}
export default AgeException;
