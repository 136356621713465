import styled from 'styled-components';
import { themeColor } from '@common/constant/theme';
import { ReactNode } from 'react';
import { sizeStyle } from '@common/constant/commonStyle';

type Props = {
  children: ReactNode;
  date: string;
};

const PatientInfoDate = ({ children, date }: Props) => {
  const week = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const day = week[new Date(date).getDay()];
  const recentDate = date.replace(/-/g, '.');
  return (
    <Container>
      <Title className="typo-body-lg">{children}</Title>
      <LastDate className="typo-display-sm">
        {recentDate !== 'null' ? recentDate : '-'}
      </LastDate>
      <LastDateDay className="typo-headline-md">
        {day !== undefined ? day : '-'}
      </LastDateDay>
    </Container>
  );
};

export default PatientInfoDate;

const Container = styled.div`
  ${sizeStyle('20%')('100%')}
`;
const Title = styled.p`
  color: ${themeColor.green_400};
  margin: 0px;
`;
const LastDate = styled.p`
  color: ${themeColor.navy};
  margin-bottom: 0px;
`;
const LastDateDay = styled.p`
  color: ${themeColor.green_600};
  margin-top: 0px;
`;
