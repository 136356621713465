import { Response } from '@common/type/response';
import Exception from './exception';

class UnAuthorizedException extends Exception {
  constructor(
    data: Response<{ field: string; message: string }[]> | undefined,
  ) {
    super(data);
  }
}
export default UnAuthorizedException;
