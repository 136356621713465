import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

if (process.env.NODE_ENV === 'mock') {
  await import('./mock/server.ts').then(async module => {
    await module.worker.start({
      onUnhandledRequest(request, print) {
        if (
          !request.url ||
          request.url === '/' ||
          request.url.includes('/assets/') ||
          request.url.includes('.svg') ||
          request.url.includes('.ts') ||
          request.url.includes('node_modules')
        ) {
          return;
        }
        print.warning();
      },
    });
  });
}

Sentry.init({
  dsn: 'https://c61ffabdc73955875eb99298ad59e73e@o4507905125515264.ingest.us.sentry.io/4507905141899264',
  enabled:
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== '127.0.0.1', // 로컬 호스트에서는 Sentry 비활성화
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev.loocusmyopia\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')! as Element).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
