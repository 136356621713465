import { useRef, useState } from 'react';

export interface UseFocus {
  focusOn: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  inputFocus: () => void;
  inputBlur: () => void;
}

const useFocus = (): UseFocus => {
  const [focusOn, setFocusOn] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputFocus = () => setFocusOn(true);
  const inputBlur = () => setFocusOn(false);

  return { focusOn, inputRef, inputFocus, inputBlur };
};

export default useFocus;
