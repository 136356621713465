import { themeColor } from '@common/constant/theme';
import Button from '@ui/common/Button';
import { useNavigate, useRouteError } from 'react-router';
import styled from 'styled-components';
import ErrorText from './ErrorText';
import { flexStyle, sizeStyle } from '@common/constant/commonStyle';
import { useEffect } from 'react';

interface Props {
  type: '404' | 'ERROR';
}

/** 내장 객체 Error하고 이름이 겹쳐서 이렇게 지었음.. */
const ErrorComponent = ({ type }: Props) => {
  const error = useRouteError();
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  };
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (error instanceof Error) {
      const isChunkLoadError =
        /ChunkLoadError|Loading chunk [\d]+ failed|Failed to load module script|Failed to fetch dynamically imported/.test(
          error.message,
        );

      if (isChunkLoadError) {
        const lastReloadTime = localStorage.getItem('lastReloadTime');
        const now = new Date().getTime();
        const ONE_HOUR = 60 * 60 * 1000;

        // 만약 새로고침이 1시간 내에 일어나지 않았다면 새로고침
        if (!lastReloadTime || now - parseInt(lastReloadTime, 10) > ONE_HOUR) {
          localStorage.setItem('lastReloadTime', now.toString());
          window.location.reload();
        }
      }
    }
  }, [error]);
  return (
    <Container $type={type}>
      <ErrorText type={type} />
      <ButtonSection>
        <Button
          size="large"
          color="rgba(0,0,0,0)"
          fontColor="dark"
          border="solid"
          style={{ width: '140px', height: '40px' }}
          onClick={goHome}>
          Go Home
        </Button>
        <Button
          size="medium"
          color={themeColor.green_400}
          fontColor="light"
          border="solid"
          style={{ width: '140px', height: '40px' }}
          onClick={goBack}>
          Go Back
        </Button>
      </ButtonSection>
    </Container>
  );
};
export default ErrorComponent;

const Container = styled.div<{ $type: string }>`
  ${flexStyle('column')('40px')('center')('center')};
  ${sizeStyle('100%')('100%')};
  padding-top: ${props => props.$type === '404' && '200px'};
`;
const ButtonSection = styled.div`
  ${flexStyle()('60px')()()}
`;
