import { atom, useAtom } from 'jotai';
import { nanoid } from 'nanoid';

interface ToastType {
  key: string;
  message: string;
  status: ToastStatus;
}
export type ToastStatus = 'default' | 'success' | 'error' | 'info' | 'warning';

interface ToastHook {
  toastList: ToastType[];
  pushToast: (message: string, status: ToastStatus) => void;
  toastLength: () => number;
}

const toastAtom = atom<ToastType[]>([]); //여기서만 사용하는 atom이라서 굳이 atoms.ts에 분리하지 않았습니다

const useToast = (): ToastHook => {
  const [toastList, setToastList] = useAtom(toastAtom);
  const duration = 6000; //인수로 받을 수도 있을 듯

  /** 토스트를 추가할 때 사용합니다 */
  const pushToast = (message: string, status: ToastStatus) => {
    const newToast: ToastType = {
      key: nanoid(),
      message: message,
      status: status,
    };
    setToastList(prev => [...prev, newToast]);
    setTimeout(() => {
      popToast();
    }, duration);
  };

  const popToast = () => {
    setToastList(prev => prev.slice(1));
  };
  /** 토스트 개수를 확인합니다. */
  const toastLength = () => {
    return toastList.length;
  };

  return { toastList, pushToast, toastLength };
};

export default useToast;
