import { flexStyle, sizeStyle } from '@common/constant/commonStyle';
import { themeColor } from '@common/constant/theme';
import { PatientResponse } from '@common/type/patient';
import { Fragment } from 'react';
import styled from 'styled-components';

type Props = {
  data: PatientResponse[];
  name: 'same' | 'similar' | 'recently';
  patientId: string;
  selectedIndex: number;
  moveFn: (url: string) => void;
};

const PatientsList = ({
  data,
  name,
  patientId,
  selectedIndex,
  moveFn,
}: Props) => {
  const values = Object.values(data);
  return (
    <>
      {values.map((ele, index) => {
        const valueLen = patientId.length;
        const patientLen = ele.patient_id.length;
        const similarNum = ele.patient_id.substring(0, valueLen);
        const remainNum = ele.patient_id.substring(valueLen, patientLen);

        const backgroundColor = (): boolean => {
          if (
            (name === 'same' && selectedIndex === -1) ||
            ((name === 'similar' || name === 'recently') &&
              selectedIndex === index)
          )
            return true;
          else return false;
        };

        if (patientId === ele.patient_id && name === 'similar')
          return <Fragment key={index} />;

        return (
          <PatientList key={index} $backgroundColor={backgroundColor()}>
            {name === 'similar' ? (
              <span>
                <PatientId $name={name}>{similarNum}</PatientId>
                {remainNum}
              </span>
            ) : (
              <span>
                <PatientId $name={name}>{ele.patient_id}</PatientId>
              </span>
            )}
            <span>{`${ele.first_name} ${ele.last_name}`}</span>
            <span>{ele.birth_date}</span>
            <span>
              <Buttons
                $name={name}
                onClick={() => {
                  moveFn(`/patient/${ele.patient_uuid}`);
                }}>
                VIEW
              </Buttons>
              &nbsp;&nbsp;
              <Buttons
                $name={name}
                onClick={() => {
                  moveFn(`/exam/${ele.patient_uuid}`);
                }}>
                + EXAM
              </Buttons>
            </span>
          </PatientList>
        );
      })}
    </>
  );
};

export default PatientsList;

const PatientList = styled.div<{ $backgroundColor: boolean }>`
  ${sizeStyle('100%')('40px')}
  ${flexStyle()()()('center')}
  padding: 0px 50px 0px 50px;
  box-sizing: border-box;
  color: ${themeColor.green_500};
  background: ${props => (props.$backgroundColor ? '#f5f8fd' : 'white')};
  span {
    ${sizeStyle('25%')()}
    text-align: start;
  }
`;
const PatientId = styled.span<{ $name: string }>`
  background: ${props =>
    props.$name === 'same'
      ? '#4FB7D0'
      : props.$name === 'similar'
      ? '#FFEBB7'
      : 'none'};
  border-radius: ${props => (props.$name === 'same' ? '9999px' : '2px')};
  color: ${props => props.$name === 'same' && 'white'};
  padding: ${props => props.$name === 'same' && '4px 10px 4px 10px'};
`;
const Buttons = styled.button<{ $name: string }>`
  ${sizeStyle('60px')('20px')}
  font-size: 12px;
  border: 1.5px solid
    ${props => (props.$name === 'same' ? '#4FB7D0' : `${themeColor.green_400}`)};
  border-radius: 9999px;
  background: transparent;
  color: ${themeColor.green_400};
  cursor: pointer;
`;
