import { Patient } from '@common/type/patient';
import { instance } from './instance';
import { AxiosError } from 'axios';
import { throwAxiosException } from '@common/utils/exception';

export class PatientApi {
  /** [postPatient] 환자 생성 */
  public static async postPatients(body: Patient) {
    return await instance
      .post(`/v1/patients`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getPatient] 환자 조회 */
  public static async getPateintsByPatientUuid(patient_uuid: string) {
    return await instance
      .get(`/v1/patients/${patient_uuid}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getPatientByPatientId] 환자 번호로 환자 목록 조회 */
  public static async getPatientsListByPatientId(
    patient_id: string,
    size: number,
  ) {
    return await instance
      .get(`/v1/patients?patient_id=${patient_id}&size=${size}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [getRecentPatient] 최근 조회한 환자 목록 조회 */
  public static async getPatientsRecentReads(size: number) {
    return await instance
      .get(`/v1/patients/recent_reads?size=${size}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [patchPatient] 환자 정보 수정 */
  public static async patchPatientsByPatientUuid(
    patient_uuid: string,
    body: Patient,
  ) {
    return await instance
      .patch(`/v1/patients/${patient_uuid}`, body)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }

  /** [deletePatient] 환자 삭제 */
  public static async deletePatientsByPatientUuid(patient_uuid: string) {
    return await instance
      .delete(`/v1/patients/${patient_uuid}`)
      .then(res => res.data)
      .catch(err => {
        if (err instanceof AxiosError) {
          throwAxiosException(err.response?.status, err, err.response?.data);
        } else throw err;
      });
  }
}
