import { Response } from '@common/type/response';
import { AxiosError } from 'axios';
import {
  AgeException,
  BadRequestException,
  ConflictException,
  EpiStatusException,
  ExpiredException,
  ForbiddenException,
  NotFoundException,
  TemporalError,
  UnAuthorizedException,
  DateException,
} from 'src/exception';

type ExceptionClass =
  | typeof BadRequestException
  | typeof EpiStatusException
  | typeof AgeException
  | typeof DateException
  | typeof NotFoundException
  | typeof ConflictException
  | typeof TemporalError
  | typeof UnAuthorizedException
  | typeof ExpiredException
  | typeof ForbiddenException;

export const throwAxiosException = (
  status: number | undefined,
  err: AxiosError,
  data: Response<{ field: string; message: string }[]> | undefined,
) => {
  const condition: [number, ExceptionClass][] = [
    [40000, BadRequestException],
    [40001, EpiStatusException],
    [40002, AgeException],
    [40004, DateException],
    [40400, NotFoundException],
    [40900, ConflictException],
    [40100, UnAuthorizedException],
    [40101, ExpiredException],
    [40300, ForbiddenException],
  ];
  // 백에서 상세코드를 주는 게 불가능한 경우를 추가해주세요
  const condition2: [number, ExceptionClass][] = [
    [403, ForbiddenException],
    [408, TemporalError],
  ];

  condition.map(([statusCode, exception]) => {
    if (statusCode === data?.code) throw new exception(data);
  });
  condition2.map(([statusCode, exception]) => {
    if (status && statusCode === status) {
      throw new exception(data);
    }
  });
  throw err;
};

/**
 * ts에서 클래스 확장을 위한 함수
 * 출처 : https://www.typescriptlang.org/docs/handbook/mixins.html#constrained-mixins
 * 원본이 any 쓰고 있어서 여기는 any 허용했습니다
 */
export function applyMixins(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  derivedCtor: any,
  constructors: any[],
) {
  constructors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
          Object.create(null),
      );
    });
  });
}
